<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
      <path fill="currentColor" d="M0 4835 l0 -285 1050 0 1050 0 -2 -912 -3 -913 -987 -3 -988 -2 0
-269 c0 -236 2 -270 16 -275 9 -3 454 -6 990 -6 l974 0 0 -800 0 -800 -1050 0
-1050 0 0 -285 0 -285 1383 2 1382 3 5 2270 c3 1249 6 2271 8 2272 1 2 523 4
1160 5 l1157 3 0 275 0 275 -25 -3 c-14 -1 -72 -14 -130 -28 -58 -14 -123 -29
-145 -34 -22 -5 -69 -16 -105 -24 -164 -38 -298 -68 -335 -76 -22 -4 -107 -22
-190 -38 -739 -152 -1174 -205 -1695 -205 -162 0 -347 5 -410 12 -63 6 -160
16 -215 22 -96 10 -164 19 -305 40 -71 10 -368 64 -440 80 -25 5 -67 13 -95
18 -27 5 -90 19 -140 31 -49 11 -108 25 -130 30 -105 24 -423 101 -450 109
-16 5 -79 21 -140 36 -60 14 -118 30 -127 36 -17 9 -18 -7 -18 -276z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "logo"
}
</script>

<style scoped>
</style>