































































import Vue from 'vue';

export default Vue.extend({
  components: {},
  data() {
    return {
      home: false,
      skills: false,
      auszeichnungen: false,
      projekte: false,
      referenzen: false,
      kontakt: false
    };
  },

  mounted() {
    if ((window.matchMedia('(prefers-color-scheme: dark)').matches && !localStorage.getItem('mode')) || localStorage.getItem('mode') === 'dark') {
      this.$vuetify.theme.dark = true;
      localStorage.setItem('mode', 'dark');
    } else if (localStorage.getItem('mode') == 'light') {
      this.$vuetify.theme.dark = false;
    }
  },
  updated() {
    if (this.$vuetify.theme.dark) {
      localStorage.setItem('mode', 'dark');
    } else {
      localStorage.setItem('mode', 'light');
    }
  }
});
