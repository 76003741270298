var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Elio Teuber, GHR-OSA-NEX-27 ")]),_c('v-card-text',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_c('v-timeline-item',{attrs:{"icon":"mdi-at"}},[_c('v-hover',{attrs:{"open-delay":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.mail('elio.teuber@swisscom.com')}}},[_c('v-card-title',[_c('v-scroll-x-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(hover)?_c('span',[_vm._v("Elio.Teuber@swisscom.com")]):_c('h4',[_vm._v("E-Mail")])])],1)],1)]}}])})],1),_c('v-timeline-item',{attrs:{"icon":"mdi-phone"}},[_c('v-hover',{attrs:{"open-delay":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.call('+41797495583')}}},[_c('v-card-title',[_c('v-scroll-x-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(hover)?_c('span',[_vm._v("+41 79 749 55 83")]):_c('h4',[_vm._v("Telefon")])])],1)],1)]}}])})],1),_c('v-timeline-item',{attrs:{"icon":"mdi-linkedin"}},[_c('v-hover',{attrs:{"open-delay":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.link('https://www.linkedin.com/in/elio-teuber-28690b1ab/')}}},[_c('v-card-title',[_vm._v(" Linkedin ")])],1)]}}])})],1),_c('v-timeline-item',{attrs:{"icon":"mdi-github"}},[_c('v-hover',{attrs:{"open-delay":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.link('https://github.com/EloiMusk')}}},[_c('v-card-title',[_c('v-scroll-x-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(hover)?_c('span',[_vm._v("EloiMusk")]):_c('h4',[_vm._v("Github")])])],1)],1)]}}])})],1),_c('v-timeline-item',{attrs:{"icon":"$vuetify.icons.thm"}},[_c('v-hover',{attrs:{"open-delay":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.link('https://tryhackme.com/p/MagicToast')}}},[_c('v-card-title',[_c('v-scroll-x-transition',{attrs:{"leave-absolute":"","hide-on-leave":""}},[(hover)?_c('span',[_vm._v("MagicToast")]):_c('h4',[_vm._v("TryHackMe")])])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }