import { render, staticRenderFns } from "./Auszeichnungen.vue?vue&type=template&id=30f122a4&scoped=true&"
import script from "./Auszeichnungen.ts?vue&type=script&lang=ts&"
export * from "./Auszeichnungen.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30f122a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VContainer,VFadeTransition,VHover,VIcon,VImg,VOverlay,VTab,VTabItem,VTabs,VTabsItems})
