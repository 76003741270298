<template>
  <svg viewBox="566.299 119.431 222.167 166.661" width="222.167" height="166.661">
    <g transform="matrix(1.333333, 0, 0, -1.333333, 566.299255, 366.103119)" id="g10">
      <g transform="scale(0.1)" id="g12">
        <path id="path32" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 976.051,894.77 v 135.19 l -34.289,-26.48 -22.621,30.93 60.55,44.46 h 39.799 v -184.1 h -43.439"></path>
        <path id="path34" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 1154.41,987.34 c 0,5.711 -0.35,12.039 -1.01,18.99 -0.7,6.91 -1.99,13.4 -3.91,19.37 -1.91,5.98 -4.65,11.02 -8.2,15.08 -3.56,4.06 -8.17,6.1 -13.91,6.1 -5.7,0 -10.39,-2.04 -14.02,-6.1 -3.67,-4.06 -6.48,-9.1 -8.48,-15.08 -1.99,-5.97 -3.32,-12.46 -4.02,-19.37 -0.7,-6.951 -1.06,-13.279 -1.06,-18.99 0,-5.891 0.36,-12.34 1.06,-19.371 0.7,-7.028 2.03,-13.52 4.02,-19.489 2,-5.98 4.81,-11.019 8.48,-15.082 3.63,-4.097 8.32,-6.128 14.02,-6.128 5.74,0 10.35,2.031 13.91,6.128 3.55,4.063 6.29,9.102 8.2,15.082 1.92,5.969 3.21,12.461 3.91,19.489 0.66,7.031 1.01,13.48 1.01,19.371 z m 44.73,0 c 0,-13 -1.29,-25.391 -3.91,-37.18 -2.57,-11.801 -6.75,-22.148 -12.46,-31.058 -5.74,-8.942 -13.12,-16.051 -22.22,-21.332 -9.1,-5.27 -20.16,-7.93 -33.17,-7.93 -13,0 -24.1,2.66 -33.28,7.93 -9.18,5.281 -16.68,12.39 -22.5,21.332 -5.78,8.91 -10,19.257 -12.62,31.058 -2.57,11.789 -3.86,24.18 -3.86,37.18 0,13.01 1.29,25.36 3.86,37.04 2.62,11.71 6.84,21.95 12.62,30.7 5.82,8.75 13.32,15.7 22.5,20.94 9.18,5.19 20.28,7.77 33.28,7.77 13.01,0 24.07,-2.58 33.17,-7.77 9.1,-5.24 16.48,-12.19 22.22,-20.94 5.71,-8.75 9.89,-18.99 12.46,-30.7 2.62,-11.68 3.91,-24.03 3.91,-37.04"></path>
        <path id="path36" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 1443.16,894.77 v 135.19 l -34.29,-26.48 -22.66,30.93 60.59,44.46 h 39.8 v -184.1 h -43.44"></path>
        <path id="path38" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 1621.52,987.34 c 0,5.711 -0.35,12.039 -1.01,18.99 -0.71,6.91 -1.99,13.4 -3.91,19.37 -1.91,5.98 -4.65,11.02 -8.2,15.08 -3.56,4.06 -8.2,6.1 -13.91,6.1 -5.7,0 -10.39,-2.04 -14.02,-6.1 -3.67,-4.06 -6.49,-9.1 -8.48,-15.08 -1.99,-5.97 -3.32,-12.46 -4.02,-19.37 -0.7,-6.951 -1.06,-13.279 -1.06,-18.99 0,-5.891 0.36,-12.34 1.06,-19.371 0.7,-7.028 2.03,-13.52 4.02,-19.489 1.99,-5.98 4.81,-11.019 8.48,-15.082 3.63,-4.097 8.32,-6.128 14.02,-6.128 5.71,0 10.35,2.031 13.91,6.128 3.55,4.063 6.29,9.102 8.2,15.082 1.92,5.969 3.2,12.461 3.91,19.489 0.66,7.031 1.01,13.48 1.01,19.371 z m 44.73,0 c 0,-13 -1.29,-25.391 -3.91,-37.18 -2.57,-11.801 -6.75,-22.148 -12.46,-31.058 -5.74,-8.942 -13.12,-16.051 -22.22,-21.332 -9.11,-5.27 -20.16,-7.93 -33.17,-7.93 -13.01,0 -24.1,2.66 -33.28,7.93 -9.18,5.281 -16.68,12.39 -22.5,21.332 -5.82,8.91 -10,19.257 -12.62,31.058 -2.57,11.789 -3.86,24.18 -3.86,37.18 0,13.01 1.29,25.36 3.86,37.04 2.62,11.71 6.8,21.95 12.62,30.7 5.82,8.75 13.32,15.7 22.5,20.94 9.18,5.19 20.27,7.77 33.28,7.77 13.01,0 24.06,-2.58 33.17,-7.77 9.1,-5.24 16.48,-12.19 22.22,-20.94 5.71,-8.75 9.89,-18.99 12.46,-30.7 2.62,-11.68 3.91,-24.03 3.91,-37.04"></path>
        <path id="path40" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 972.031,605 v 135.199 l -34.332,-26.527 -22.621,30.937 60.582,44.493 h 39.81 V 605 h -43.439"></path>
        <path id="path42" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 1125.08,605 v 135.199 l -34.34,-26.527 -22.62,30.937 60.59,44.493 h 39.81 V 605 h -43.44"></path>
        <path id="path44" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 1277.46,605 v 135.199 l -34.34,-26.527 -22.57,30.937 60.54,44.493 h 39.81 V 605 h -43.44"></path>
        <path id="path46" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 1463.71,697.582 c 0,5.699 -0.35,12.027 -1.05,18.938 -0.67,6.96 -2,13.402 -3.87,19.378 -1.91,5.981 -4.65,11.012 -8.2,15.082 -3.56,4.102 -8.21,6.129 -13.91,6.129 -5.74,0 -10.39,-2.027 -14.06,-6.129 -3.64,-4.07 -6.45,-9.101 -8.44,-15.082 -1.99,-5.976 -3.32,-12.418 -4.02,-19.378 -0.71,-6.911 -1.06,-13.239 -1.06,-18.938 0,-5.902 0.35,-12.352 1.06,-19.383 0.7,-7.027 2.03,-13.508 4.02,-19.488 1.99,-5.981 4.8,-11.012 8.44,-15.082 3.67,-4.098 8.32,-6.129 14.06,-6.129 5.7,0 10.35,2.031 13.91,6.129 3.55,4.07 6.29,9.101 8.2,15.082 1.87,5.98 3.2,12.461 3.87,19.488 0.7,7.031 1.05,13.481 1.05,19.383 z m 44.73,0 c 0,-13.012 -1.29,-25.391 -3.91,-37.191 -2.62,-11.801 -6.76,-22.153 -12.5,-31.09 -5.7,-8.91 -13.12,-16.02 -22.23,-21.289 -9.1,-5.313 -20.11,-7.93 -33.12,-7.93 -13.01,0 -24.1,2.617 -33.28,7.93 -9.18,5.269 -16.68,12.379 -22.5,21.289 -5.82,8.937 -10,19.289 -12.62,31.09 -2.58,11.8 -3.9,24.179 -3.9,37.191 0,12.969 1.32,25.348 3.9,37.027 2.62,11.723 6.8,21.911 12.62,30.661 5.82,8.789 13.32,15.75 22.5,20.98 9.18,5.16 20.27,7.77 33.28,7.77 13.01,0 24.02,-2.61 33.12,-7.77 9.11,-5.23 16.53,-12.191 22.23,-20.98 5.74,-8.75 9.88,-18.938 12.5,-30.661 2.62,-11.679 3.91,-24.058 3.91,-37.027"></path>
        <path id="path74" style="fill-opacity:1;fill-rule:nonzero;stroke:none" fill="currentColor"
              d="m 1600.16,1210.51 c -30.82,150.58 -164.93,259.88 -318.87,259.88 -23.44,0 -46.49,-2.66 -68.95,-7.5 -37.42,219.49 -228.86,387.15 -458.86,387.15 -227.859,0 -417.859,-164.57 -457.742,-381.06 C 130.16,1453.91 0,1314.38 0,1144.92 0,965.469 146.02,819.488 325.469,819.488 h 486.953 c 24.848,0 45,20.121 45,45 0,24.852 -20.152,45 -45,45 H 325.469 C 195.621,909.488 90,1015.12 90,1144.92 c 0,129.85 105.621,235.47 235.469,235.47 47.191,0 92.691,-13.87 131.601,-40.16 20.629,-13.9 48.59,-8.51 62.5,12.07 13.91,20.59 8.481,48.6 -12.109,62.5 -36.762,24.85 -77.813,41.45 -120.902,49.65 36.761,168.79 187.269,295.59 366.921,295.59 191.399,0 349.68,-143.91 372.61,-329.18 -12.07,-6.56 -23.86,-13.75 -35.15,-21.91 -20.16,-14.54 -24.69,-42.66 -10.16,-62.82 14.57,-20.15 42.7,-24.68 62.85,-10.15 40.24,29.06 87.85,44.41 137.66,44.41 111.41,0 208.4,-79.02 230.66,-187.97 4.38,-21.29 23.13,-35.97 44.07,-35.97 2.96,0 6.01,0.31 9.06,0.93 24.33,4.96 40.04,28.75 35.08,53.13"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "thm"
}
</script>

<style scoped>

</style>